import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const SkillsChart = () => {
  const data = {
    labels: ['Web Development', 'App Development', 'AI/ML', 'Cybersecurity', 'Networking', 'Database Management'],
    datasets: [
      {
        label: 'Skill Level',
        data: [9, 8, 7, 8, 7, 9],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      r: {
        angleLines: {
          display: false
        },
        suggestedMin: 0,
        suggestedMax: 10
      }
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto' }}>
      <h2>Skills Overview</h2>
      <Radar data={data} options={options} />
    </div>
  );
};

export default SkillsChart;