import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Container = styled.div`
  padding: 4rem 0;
  background: linear-gradient(-45deg, #01796f, #006684, #004953, #023430, #00332e);

  background-size: 400% 400%;
  animation: ${gradientAnimation} 20s ease infinite;
  color: white;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(1, 121, 111, 0.5);
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const FilterButton = styled.button`
  background: ${props => props.active ? 'rgba(1, 121, 111, 0.5)' : 'transparent'};
  color: white;
  border: 1px solid #01796f;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 20px;

  &:hover {
    background: rgba(1, 121, 111, 0.3);
    box-shadow: 0 0 10px rgba(1, 121, 111, 0.5);
  }
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 0 2rem;
`;

const ProjectCard = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  animation: ${fadeIn} 0.5s ease-out;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(1, 121, 111, 0.3);
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;

  ${ProjectCard}:hover & {
    transform: scale(1.1);
  }
`;

const ProjectInfo = styled.div`
  padding: 1.5rem;
`;

const ProjectTitle = styled.h3`
  margin-bottom: 0.5rem;
  color: #ffffff;
  font-size: 1.4rem;
`;

const ProjectDescription = styled.p`
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1rem;
`;

const ProjectLink = styled.a`
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #01796f;
  color: white;
  text-decoration: none;
  font-weight: bold;
  border-radius: 20px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #004953;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(1, 121, 111, 0.4);
  }
`;

const projectData = [
  
    {
      id: 1,
      image: "./resilientweb.png",
      title: "Resilient Risk Consulting ",
      description: "Wix Studio Custom, Wix, VELO, HTML, JavaScript",
      category: "web",
      reverseImage: "./resback.png",
      link: "https://www.resilientriskconsulting.com",
    },
    {
      id: 2,
      image: "./kaiteriteri.png",
      title: "Kaiteriteri NZ",
      description: "Silverstripe CMS, HTML, JavaScript, Python",
      category: "web",
      reverseImage: "./exkback.png",
      link: "https://www.kaiteriterinz.com",
    },
    {
      id: 3,
      image: "./kmtb.png",
      title: "Kaiteriteri MTB Park",
      description: "Squarespace Custom, HTML",
      category: "web",
      reverseImage: "./kmtbback.png",
      link: "https://www.kaiteriterimtbpark.com",
    },
    {
      id: 4,
      image: "./Chris Hoy.png",
      title: "Chrishoy.co.uk",
      description: "Wordpress, HTML, JavaScript, PHP",
      category: "web",
      reverseImage: "./chrishoyback.png",
      link: "https://www.chrishoy.co.uk",
    },
    {
      id: 5,
      image: "./swingsuite.png",
      title: "The Swing Suite",
      description: "Wix Custom, Wix Velo, HTML, JavaScript",
      category: "web",
      reverseImage: "./swingsuiteback.png",
      link: "https://www.theswingsuite.co.uk",
    },
    {
      id: 6,
      image: "./jerome.png",
      title: "Jerome Belau Ceramiste",
      description: "Squarespace Custom, HTML, JavaScript",
      category: "web",
      reverseImage: "./jeromeback.png",
      link: "https://www.jeromebelau.com",
    },
    {
      id: 7,
      image: "./BA.png",
      title: "Rebecca Adlington",
      description: "Wix Velo Custom, JavaScript",
      category: "web",
      reverseImage: "",
      link: "https://www.rebeccaadlington.co.uk",
    },
    {
      id: 8,
      image: "./tgi.png",
      title: "TGI Sports",
      description: "Wordpress, HTML, PHP, JavaScript",
      category: "web",
      reverseImage: "",
      link: "https://www.tgisport.com",
    },
    {
      id: 9,
      image: "./swikeweb.png",
      title: "Swike",
      description: "JavaScript, Python, AWS",
      category: "app",
      reverseImage: "./jeromeback.png",
      link: "https://www.swike.com",
    },
    {
      id: 10,
      image: "./gt.png",
      title: "Guitar Tuner",
      description: "HTML, JavaScript, Python",
      category: "app",
      reverseImage: "",
      link: "https://tuneme-9e374.web.app/",
    },
    {
      id: 11,
      image: "./golfiq.png",
      title: "Golf IQ",
      description: "JavaScript, Firebase, Firestore, Vertex AI",
      category: "app",
      reverseImage: "",
      link: "https://www.golfiq.me",
    },
  ];

  const Projects = () => {
    const [filter, setFilter] = useState("all");
  
    const filteredProjects = projectData.filter(project => 
      filter === "all" || project.category === filter
    );
  
    return (
      <Container id="projects">
        <Title>My Projects</Title>
        <FilterContainer>
          <FilterButton active={filter === "all"} onClick={() => setFilter("all")}>All</FilterButton>
          <FilterButton active={filter === "web"} onClick={() => setFilter("web")}>Web</FilterButton>
          <FilterButton active={filter === "app"} onClick={() => setFilter("app")}>App</FilterButton>
        </FilterContainer>
        <ProjectGrid>
          {filteredProjects.map(project => (
            <ProjectCard key={project.id}>
              <ProjectImage src={project.image} alt={project.title} />
              <ProjectInfo>
                <ProjectTitle>{project.title}</ProjectTitle>
                <ProjectDescription>{project.description}</ProjectDescription>
                <ProjectLink href={project.link} target="_blank" rel="noopener noreferrer">View Project</ProjectLink>
              </ProjectInfo>
            </ProjectCard>
          ))}
        </ProjectGrid>
      </Container>
    );
  };
  
  export default Projects;