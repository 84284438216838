import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faShieldAlt,
  faDatabase,
  faGamepad,
  faMobileAlt,
  faNetworkWired,
  faMicrophoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { SiTensorflow, SiLinux } from "react-icons/si";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #1a1a1a 0%, #2a2a2a 100%);
  color: #f8f8ff;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  color: white;
`;

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 3rem;
`;

const IconCard = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  transition: all 0.3s ease;
  cursor: pointer;
  animation: ${fadeIn} 0.5s ease-out;
  position: relative;
  grid-column: span ${props => (props.expanded ? 3 : 1)};
  height: auto;
  width: 100%;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    grid-column: span 1;
  }
`;

const IconContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  font-size: 3.5rem;
  color: ${props => props.color || '#61dafb'};
  margin-right: 1rem;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

const IconTitle = styled.h3`
  font-size: 1.4rem;
  color: #f8f8ff;
  margin-right: 1rem;
  text-align: center;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

const Description = styled.div`
  text-align: left;
  color: #f8f8ff;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  line-height: 1.8;
  animation: ${fadeIn} 0.5s ease-out;
  margin-left: 1rem;
  flex: 2;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
  }
`;

const Work = () => {
  const [selectedIcon, setSelectedIcon] = useState(null);

  const icons = [
    {
      Icon: faGlobe,
      name: "Globe",
      title: "Web Development",
      color: "#61dafb",
      description:
        "My journey in Web Development intertwines a love for coding with a passion for design. I specialize in creating websites that are as aesthetically pleasing as they are functional, using React, HTML5, and CSS3. I enjoy exploring new design trends and implementing them in my projects, ensuring each site I build is at the forefront of digital innovation.",
    },
    {
      Icon: faMobileAlt,
      name: "Mobile",
      title: "App Development",
      color: "#3498db",
      description:
        "In App Development, my goal is to build apps that are not just useful but also a joy to use. My interest lies in developing intuitive user interfaces with React Native, ensuring a seamless experience across different devices. I take pride in staying updated with the latest technological advancements to bring state-of-the-art features to the apps I develop.",
    },
    {
      Icon: SiTensorflow,
      name: "Tensorflow",
      title: "AI and Machine Learning",
      color: "#ff6b6b",
      description:
        "AI and Machine Learning are areas where my fascination with technology meets real-world applications. My work involves using TensorFlow to create models that can learn and adapt. I'm particularly interested in the ethical implications of AI and strive to develop solutions that are both innovative and responsible. Alongside this, I'm exploring applications in Quantum computing, and utilizing up and coming technologies such as the OpenAI API and Google Deepmind Gemini to push my capabilities further.",
    },
    {
      Icon: faShieldAlt,
      name: "Shield",
      title: "CyberSecurity",
      color: "#4ecdc4",
      description:
        "In CyberSecurity, my focus is on safeguarding digital assets. I'm drawn to the challenge of staying ahead of constantly evolving security threats. I invest time in learning about the latest security technologies and strategies, ensuring the highest level of protection for data and systems I work with. I have completed a number of qualifications in this field and I'm actively pursuing my CompTIA certification.",
    },
    {
      Icon: faNetworkWired,
      name: "Sitemap",
      title: "Networks",
      color: "#f39c12",
      description:
        "My involvement in Networks stems from an interest in how data moves across the digital world. I enjoy setting up and managing network infrastructures, ensuring efficient and secure data flow. My work includes configuring network hardware and using Linux systems to optimize network performance.",
    },
    {
      Icon: faDatabase,
      name: "Database",
      title: "Databasing",
      color: "#9b59b6",
      description:
        "Databasing for me is about creating order from chaos. I am skilled in managing SQL and NoSQL databases, focusing on data organization, security, and performance. I find satisfaction in structuring data in ways that make it easily accessible and usable.",
    },
    {
      Icon: faGamepad,
      name: "Gamepad",
      title: "Game Design",
      color: "#2ecc71",
      description:
        "Game Design combines my technical skills with my creative aspirations. I enjoy using platforms like Unity and the Unreal Engine to bring imaginative game worlds to life. My interest extends to creating compelling game mechanics and narratives, exploring ways to design a unique experience.",
    },
    {
      Icon: faMicrophoneAlt,
      name: "Microphone",
      title: "Audio Engineering",
      color: "#e74c3c",
      description:
        "As an Audio Engineer, I blend technical expertise with artistic sensibility to produce high-quality audio content for a range of applications. I use a number of software, including Ableton Live and Adobe Audition. I also have an active interest in audio VST development and engineering, though I'm yet to delve deeper into this field.",
    },
    {
      Icon: SiLinux,
      name: "Linux",
      title: "Linux Systems",
      color: "#f1c40f",
      description:
        "My Linux Systems expertise is rooted in managing and optimizing open-source platforms for maximum efficiency and performance.",
    },
  ];

  const handleClick = (name) => {
    setSelectedIcon(selectedIcon === name ? null : name);
  };

  return (
    <Container id="experience">
      <Title>My Areas of Experience</Title>
      <IconGrid>
        {icons.map((icon, index) => (
          <IconCard
            key={index}
            expanded={selectedIcon === icon.name}
            onClick={() => handleClick(icon.name)}
          >
            <IconContent>
              <IconWrapper color={icon.color}>
                {icon.Icon === SiTensorflow || icon.Icon === SiLinux ? (
                  <icon.Icon size="1em" />
                ) : (
                  <FontAwesomeIcon icon={icon.Icon} />
                )}
              </IconWrapper>
              <IconTitle>{icon.title}</IconTitle>
            </IconContent>
            {selectedIcon === icon.name && (
              <Description>
                {icon.description}
              </Description>
            )}
          </IconCard>
        ))}
      </IconGrid>
    </Container>
  );
};

export default Work;
