import React from "react";
import styled from "styled-components";
import HeroSection from "../components/HeroSection";
import TechnologyIcons from "../components/TechnologyIcons";
import Mission from "../components/Mission";
import SkillsChart from "../components/SkillsChart";
import Projects from "../components/Projects";
import Work from "../components/Work";
import Partners from "../components/Partners";
import { FadeInSection, SlideInSection, ScaleInSection } from "../components/AnimationComponents";

const HomeContainer = styled.div``;

const Pagebreak = styled.div`
  height: 10vh;
  background: url("/OIP.jpg") no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
`;

const Home = () => {
  return (
    <HomeContainer>
      <FadeInSection>
        <HeroSection />
      </FadeInSection>

      <SlideInSection>
        <Mission id="mission" />
      </SlideInSection>

      

      <FadeInSection>
        <Work />
      </FadeInSection>

      


      <FadeInSection>
        <TechnologyIcons />
      </FadeInSection>

      

      <FadeInSection>
        <Partners />
      </FadeInSection>

      <FadeInSection>
        <Projects />
      </FadeInSection>
    </HomeContainer>
  );
};

export default Home;