import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaReact, FaNodeJs, FaPython } from "react-icons/fa";
import {
  SiUnity,
  SiMicrosoftazure,
  SiAdobecreativecloud,
  SiOpenai,
  SiAwsamplify,
  SiDiscord,
  SiFirebase,
  SiSqlite,
  SiLinux,
} from "react-icons/si";

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  background: #f8f8ff;
  padding: 2rem;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
  tezt-align: center;
  font-size: 2rem;
  margin-bottom: 5rem;
`;

const IconSectionContainer = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  margin-top: 2rem;
`;

const IconSection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${rotateAnimation} 60s linear infinite;
`;

const IconStyle = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => props.iconBackground || "#004040"};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  }

  svg {
    width: 60%;
    height: 60%;
    color: ${props => props.iconColor || "#61dafb"};
  }
`;

const CenterText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
  color: #333;
  text-align: center;
  width: 80%;
  pointer-events: none;
`;

const TechnologyIcons = () => {
  const [hoveredIcon, setHoveredIcon] = useState("My Technologies");

  const icons = [
    { Icon: FaReact, name: "React", color: "#61dafb", background: "#282c34" },
    { Icon: SiDiscord, name: "Discord", color: "white", background: "#5a4fcf" },
    { Icon: FaNodeJs, name: "Node.js", color: "#32cd32", background: "black" },
    { Icon: SiFirebase, name: "Firebase", color: "#ffe135", background: "#F5820D" },
    { Icon: SiUnity, name: "Unity", color: "#ffffff", background: "#000000" },
    { Icon: SiSqlite, name: "SQLite", color: "#0f80cc", background: "#white" },
    { Icon: SiMicrosoftazure, name: "Azure", color: "#0089D6", background: "white" },
    { Icon: SiAwsamplify, name: "Amplify", color: "white", background: "#FF9900" },
    { Icon: FaPython, name: "Python", color: "#ffd43b", background: "#306998" },
    { Icon: SiOpenai, name: "OpenAI", color: "white", background: "#000000" },
    { Icon: SiAdobecreativecloud, name: "Adobe", color: "white", background: "#DA1F26" },
    { Icon: SiLinux, name: "Linux", color: "white", background: "#000000" },
  ];

  return (
    <Container>
      <Title>My Technology Stack</Title>
      <IconSectionContainer>
        <IconSection>
          {icons.map(({ Icon, name, color, background }, index) => {
            const angle = (index / icons.length) * 2 * Math.PI;
            const radius = 150; // Half of container width minus half of icon width
            const x = Math.cos(angle) * radius + 150 - 20; // 150 is half of container width, 20 is half of icon width
            const y = Math.sin(angle) * radius + 150 - 20; // 150 is half of container height, 20 is half of icon height

            return (
              <IconStyle
                key={index}
                style={{ left: `${x}px`, top: `${y}px` }}
                iconColor={color}
                iconBackground={background}
                onMouseEnter={() => setHoveredIcon(name)}
                onMouseLeave={() => setHoveredIcon("My Technologies")}
              >
                <Icon />
              </IconStyle>
            );
          })}
        </IconSection>
        <CenterText>{hoveredIcon}</CenterText>
      </IconSectionContainer>
    </Container>
  );
};

export default TechnologyIcons;