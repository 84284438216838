import { FaReact, FaNodeJs, FaPython } from "react-icons/fa";
import {
  SiUnity,
  SiMicrosoftazure,
  SiAdobecreativecloud,
  SiOpenai,
  SiAwsamplify,
  SiDiscord,
  SiFirebase,
  SiSqlite,
  SiLinux,
} from "react-icons/si";

export const techIcons = [
  { name: 'React', Icon: FaReact, color: '#61DAFB' },
  { name: 'Node.js', Icon: FaNodeJs, color: '#339933' },
  { name: 'Python', Icon: FaPython, color: '#3776AB' },
  { name: 'Unity', Icon: SiUnity, color: '#000000' },
  { name: 'Azure', Icon: SiMicrosoftazure, color: '#0089D6' },
  { name: 'Adobe', Icon: SiAdobecreativecloud, color: '#FF0000' },
  { name: 'OpenAI', Icon: SiOpenai, color: '#412991' },
  { name: 'AWS', Icon: SiAwsamplify, color: '#FF9900' },
  { name: 'Discord', Icon: SiDiscord, color: '#7289DA' },
  { name: 'Firebase', Icon: SiFirebase, color: '#FFCA28' },
  { name: 'SQLite', Icon: SiSqlite, color: '#003B57' },
  { name: 'Linux', Icon: SiLinux, color: '#FCC624' },
];