import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalStyles from "./styles/GlobalStyles";
import styled, { keyframes } from "styled-components";
import Header from "./components/Header";
import Home from "./pages/Home";
import Mission from "./components/Mission";
import Work from "./components/Work";
import Projects from "./components/Projects";

const HeaderContainer = styled.div`
  z-index: 9999;
  position: fixed; // Changed from sticky to fixed
  top: 0;
  width: 100%; // Ensure the header spans the entire width
`;

function App() {
  return (
    <Router>
      <GlobalStyles />
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mission" element={<Mission/>} />
        <Route path="/experience" element={<Work/>} />
        <Route path="/projects" element={<Projects/>} />
      </Routes>
    </Router>
  );
}

export default App;
