import React from "react";
import styled from "styled-components";



const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem;
  position: sticky;
  top: 0;

  @media (max-width: 768px) {
  background-color: rgba(0, 0, 0, 0.8);
`;

const Logo = styled.div`
  font-size: 1.5rem;
  color: #fff;
  font-family: "Montserrat", sans-serif; // Example font
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 1rem;
`;

const NavLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  &:hover {
    text-decoration: underline;
  }
`;

const SignInButton = styled.button`
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 0.5rem 1rem;
  cursor: pointer;
`;

const Header = () => {
  
  const scrollToSection = (id) => {
    const element = document.querySelector(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <HeaderContainer>
      <a
        href="/#"
        onClick={(e) => {
          e.preventDefault();
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      >
        <Logo>SW</Logo>
      </a>
      <NavLinks>
        <NavLink
          href="#mission"
          onClick={(e) => {
            e.preventDefault();
            scrollToSection("#mission");
          }}
        >
          About
        </NavLink>
        <NavLink
          href="#experience"
          onClick={(e) => {
            e.preventDefault();
            scrollToSection("#experience");
          }}
        >
          Experience
        </NavLink>
        <NavLink
          href="#projects"
          onClick={(e) => {
            e.preventDefault();
            scrollToSection("#projects");
          }}
        >
          Projects
        </NavLink>
      </NavLinks>
    </HeaderContainer>
  );
};

export default Header;