import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Typed from "typed.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { techIcons } from "./techIcons"; // Make sure this path is correct

const HeroSectionContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: #0a0a0a;
`;

const IconsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const TechIcon = styled.div`
  position: absolute;
  font-size: ${props => props.size}px;
  color: ${props => props.color};
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.2);
  }
`;

const Content = styled.div`
  text-align: center;
  color: #ffffff;
  z-index: 10;
  position: relative;
`;

const Title = styled.h1`
  font-size: 4rem;
  margin-bottom: 1rem;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
`;

const Subtitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.7);
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

const IconLink = styled.a`
  color: #ffffff;
  font-size: 1.5rem;
  transition: color 0.3s ease, text-shadow 0.3s ease;

  &:hover {
    color: #61dafb;
    text-shadow: 0 0 10px rgba(97, 218, 251, 0.7);
  }
`;

const HeroSection = () => {
  const el = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [iconStates, setIconStates] = useState([]);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["Software Engineer", "Web Developer", "UI/UX Designer"],
      typeSpeed: 50,
      backSpeed: 50,
      loop: true
    });

    return () => {
      typed.destroy();
    };
  }, []);

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    if (dimensions.width === 0 || dimensions.height === 0) return;

    const initialIconStates = techIcons.map(() => ({
      x: Math.random() * dimensions.width,
      y: Math.random() * dimensions.height,
      vx: (Math.random() - 0.5) * 2,
      vy: (Math.random() - 0.5) * 2,
      size: Math.random() * 20 + 20
    }));

    setIconStates(initialIconStates);

    const moveIcons = () => {
      setIconStates(prevStates => prevStates.map(state => {
        let newX = state.x + state.vx;
        let newY = state.y + state.vy;
        let newVx = state.vx;
        let newVy = state.vy;

        if (newX < 0 || newX > dimensions.width) newVx = -newVx;
        if (newY < 0 || newY > dimensions.height) newVy = -newVy;

        return {
          ...state,
          x: newX,
          y: newY,
          vx: newVx,
          vy: newVy
        };
      }));
    };

    const intervalId = setInterval(moveIcons, 50); // Adjust this value to change animation speed

    return () => clearInterval(intervalId);
  }, [dimensions]);

  return (
    <HeroSectionContainer>
      <IconsContainer>
        {iconStates.map((state, index) => {
          const IconComponent = techIcons[index].Icon;
          return (
            <TechIcon
              key={index}
              style={{
                top: `${state.y}px`,
                left: `${state.x}px`,
              }}
              size={state.size}
              color={techIcons[index].color}
            >
              <IconComponent />
            </TechIcon>
          );
        })}
      </IconsContainer>
      <Content>
        <Title>Scott Winyard</Title>
        <Subtitle>
          I'm a <span ref={el} />
        </Subtitle>
        <SocialLinks>
          <IconLink href="https://www.linkedin.com/in/scottwinyard" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} />
          </IconLink>
          <IconLink href="https://github.com/jeggsy" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub} />
          </IconLink>
          <IconLink href="mailto:s.winyard@hotmail.com">
            <FontAwesomeIcon icon={faEnvelope} />
          </IconLink>
          <IconLink href="./SWCV.pdf" download>
            <FontAwesomeIcon icon={faFileDownload} />
          </IconLink>
        </SocialLinks>
      </Content>
    </HeroSectionContainer>
  );
};

export default HeroSection;