import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const Container = styled.div`
  display: flex;
  padding: 15vh 0;
  min-height: 70vh;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  color: white;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const TextSection = styled.div`
  color: #f5f5f5;
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: center;
  max-width: 800px;
  margin-bottom: 2rem;
`;

const HighlightBox = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 1.5rem;
  margin-top: 2rem;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 90%;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const HighlightTitle = styled.h3`
  font-size: 1.3rem;
  margin-bottom: 1rem;

  
`;

const HighlightContent = styled.p`
  font-size: 1rem;
  line-height: 1.5;
`;

const Mission = () => {
  const [activeHighlight, setActiveHighlight] = useState(null);

  const highlights = [
    {
      title: "Web Development Expertise",
      content: "Crafting responsive and dynamic websites using modern frameworks like React, Vue.js, and Angular. Implementing server-side rendering and optimizing for performance."
    },
    {
      title: "Mobile App Innovation",
      content: "Building cross-platform mobile applications with React Native and Flutter. Creating seamless user experiences and integrating cutting-edge features."
    },
    {
      title: "Full-Stack Proficiency",
      content: "Leveraging a strong foundation in both front-end and back-end technologies to create comprehensive, scalable solutions."
    }
  ];

  return (
    <Container id="mission" >
      <ContentWrapper>
        <Title>Transforming Ideas into Digital Reality</Title>
        <TextSection>
          With a solid educational foundation in Computer Science and Software Engineering, I am passionately navigating the ever-evolving landscape of web and mobile application development. My journey is marked by a continuous pursuit of innovation, creating digital solutions that not only meet but exceed client expectations. From responsive web designs to intuitive mobile apps, my goal is to blend creativity with functionality, ensuring each project stands out in the digital realm.
        </TextSection>
        {highlights.map((highlight, index) => (
          <HighlightBox 
            key={index}
            onClick={() => setActiveHighlight(activeHighlight === index ? null : index)}
          >
            <HighlightTitle>{highlight.title}</HighlightTitle>
            {activeHighlight === index && (
              <HighlightContent>{highlight.content}</HighlightContent>
            )}
          </HighlightBox>
        ))}
      </ContentWrapper>
    </Container>
  );
};

export default Mission;