import React, { useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";

const scrollAnimation = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
`;

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  background: linear-gradient(to right, #1a1a1a, #2a2a2a, #1a1a1a);
  padding: 40px 0;
  display: flex;
  align-items: center;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 20px 0;
    height: 300px;
  }
`;

const LogoTrack = styled.div`
  display: flex;
  width: calc(200%); /* Ensuring the track is wide enough for the animation */
  animation: ${scrollAnimation} 45s linear infinite;
  align-items: center;

  &:hover {
    animation-play-state: paused;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  padding: 0 20px;
  align-items: center;
  flex-shrink: 0;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Png = styled.img`
  max-width: 100px;
  max-height: 60px;
  min-width: 60px;
  object-fit: contain;
  filter: grayscale(100%);
  transition: filter 0.3s ease, transform 0.3s ease;
  padding: 0 20px;

  &:hover {
    filter: none;
    transform: scale(1.2);
  }

  @media (max-width: 768px) {
    max-width: 180px;
    max-height: 180px;
    min-width: 120px;
  }
`;

const logos = [
  { src: "/jeromesig.png", alt: "Jerome Belau" },
  { src: "/WNLogo.png", alt: "Wincor Nixdorf" },
  { src: "/reslogo.png", alt: "Resilient Risk Consultant" },
  { src: "/TLAwhite.png", alt: "TLA Worldwide" },
  { src: "/ss.png", alt: "The Swing Suite" },
  { src: "/Hoy.png", alt: "Chris Hoy" },
  { src: "/Tesco.png", alt: "Tesco" },
  { src: "/royal.png", alt: "Royal Tutors" },
  { src: "/Kaiteriterilogo.png", alt: "KRR" },
  { src: "/kmtblogo.png", alt: "KMTB" },
  { src: "/swike.png", alt: "Swike" },
  { src: "/Microsoft.png", alt: "Microsoft" },
];


  
  
  

  const Partners = () => {
    return (
      <Container>
        <LogoTrack>
          {logos.concat(logos).map((logo, index) => (
            <LogoWrapper key={index}>
              <Png src={logo.src} alt={logo.alt} />
            </LogoWrapper>
          ))}
        </LogoTrack>
      </Container>
    );
  };
  

export default Partners;